@import "../../_variables.scss", "../../effects";

.registration_box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    animation: show-in $transition-time ease-in-out;
    animation-fill-mode: both;

    &>section {
        width: 100%;
        max-width: 800px;
        min-width: 310px;
        height: 620px;
        min-height: 450px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        animation: show-in 0.3s ease-in-out;

        &>div:nth-child(1) {
            background-image: url("../../../../public/assets/mockup_of_crumpled_poster_on_old_grunge_wall (1) (1).jpg");
            width: 100%;
            height: 100%;
            background-position: center;
            position: absolute;
            background-size: 160%;
            background-repeat: no-repeat;
            filter: blur(3px);
            animation: show-in-above 0.8s ease-in-out;
        }

        &>.registration_wrapper {
            background-color: rgba(255, 255, 255, 0.884);
            animation: show-in-above 0.8s ease-in-out;
            width: 100%;
            height: 100%;
            max-height: 600px;
            max-width: 600px;
            min-width: $min-width;
            display: flex;
            gap: 30px;
            @include padding-sides();
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: $br-rd;
            position: relative;

            &>h3 {
                display: flex;
                font-size: 20px;
                height: 50px;
                align-items: center;
                justify-content: center;
            }

            &>p {
                text-align: center;
                line-height: 25px;
                height: 100px;
            }

            &>div {
                width: 100%;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                &:nth-child(3) {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }

                &:nth-child(4) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;

                    &>.radio_button {
                        width: 100px;
                    }
                }
            }
        }


    }
}

.buttons-wrapper {
    justify-content: space-between !important;
}



.registration_wrapper>div:last-child>button {
    width: 130px;
    height: 35px;
    border-radius: 3px;
    background: linear-gradient(90deg, #B637B6 0%, #6E1770 100%);
    color: white;
}

.registration_wrapper_success_message {

    background-color: white;
    padding: 15px 25px;
    display: block;
    height: 70%;
    width: 90%;
    border-radius: 4px;

    &>div:nth-child(1) {
        height: 80%;

        &>h3 {
            display: flex;
            text-transform: uppercase;
            font-size: 20px;
            letter-spacing: 2px;
            color: var(--f-c-3);
            font-weight: 900;
            align-items: center;
            justify-content: center;
        }

        &>p {
            font-size: 14px;
            font-weight: 600;
            line-height: 25px;
            text-align: center;
        }
    }

    &>div:nth-child(2) {
        height: 20%;
        display: flex;
        justify-content: space-around;

        &>button {
            height: 40px;
            width: 200px;
            background: linear-gradient(90deg, var(--f-c2-1), var(--f-c-1));
        }
    }

}

.signup-success-message {
    background-color: rgba(255, 255, 255, 0.884);
    animation: show-in-above 0.8s ease-in-out;
    width: 100%;
    height: 100%;
    max-height: 500px;
    max-width: 600px;
    min-width: $min-width;
    display: flex;
    gap: 30px;
    @include padding-sides();
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: $br-rd;
    position: relative;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;

    & > h3,&>h2 {
        font-size: 1.3rem;
    }
    & > p{
        margin: 20px 0;
    }
    
    & > button {
        margin-top: 40px;
    }

}


@media only screen and (max-width:700px) {

    .registration_wrapper {
        width: 100%;
        border-radius: 0px;
        padding: 10px 0;
        height: 100vh !important;
        max-height: 100vh !important;
        
    }

    .signup-success-message {
        height: 100vh !important;
        max-height: 100vh !important;
        border-radius: 0px;
    }

}