@keyframes show-in {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
    
}

@keyframes show-in-from-below {
    0%{
        opacity: 0;
        transform:translateY(150px);
    }
    100%{
        opacity: 1;
        transform:translateY(0);
    }
    
}

@keyframes screen-opens {
    0%{
       filter: brightness(0%);
    }
    100%{
        filter: brightness(100%);
    }
    
}

@keyframes heartbeat-and-twist {
    0%{
        transform: scale(1);
    }
    25%{
        transform: scale(0.9);
    }
    
    50%{
        transform: scale(1) rotate(360deg);
    }
    75%{
        
        transform: scale(0.9) rotate(360deg);
    }
    100%{
        transform: scale(1) rotate(360deg);
    }

}

@keyframes zoom-in-out-movement {
    0%{
        transform: scale(1);
    }
    25%{
        transform: scale(1.1) translateX(10px) translateY(10px);
    }
    50%{
        transform: scale(1.1) translateX(10px) translateY(10px);
    }
    100%{
        transform: scale(1);
    }

}