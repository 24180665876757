.navbar_container {
    width: 100%;
    height: 100%;
    max-height: 60px;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 10;
    transition: 0.6s;
    
}

.navbar_container.hide {
    transform: translateY(-60px);
}




.navbar_container * {
    color: white;
    font-family: var(--font-mont);
    font-size: 13px;
}

.navbar {
    width: 100%;
}

.navbar>div:nth-child(1) {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;

}

.navbar>div:nth-child(1)>a {
    background-color: rgba(0, 0, 0, 0.432);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.4s;
    width: 130px;
    padding: 10px;
    
    cursor: pointer;
    min-height: 40px;
    border-radius: 8px;
}

.navbar>div:nth-child(1)>div {

    background-color: rgba(0, 0, 0, 0.432);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.4s;
    width: 130px;
    padding: 10px;
    margin: 0 16px;
    cursor: pointer;
    min-height: 50px;
    
}

.admin_nav_btn>a {

    display: flex;
    justify-content: space-between;

}

.admin_nav_btn>a>span {
    display: block;

    margin: auto;
    width: 100%;
}

.navbar>div:nth-child(1)>a:nth-child(1) img {
    width: 18px;
    height: 15px;

}


.navbar>div>a>span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0px;
    opacity: 0;
    transition: 0.6s;

}

.navbar>div>a>img,
.navbar>div img {
    /* position: absolute; */
    width: 20px;
    height: 20px;

}

.navbar>div>a:nth-child(1) img {
    width: 23px;
    height: 18px;


}


.navbar>div>a:hover span {

    width: 80%;
    opacity: 1;
    /* transform: scale(1.4); */
}






.login_container_option a:hover {
    transform: scale(1.4);
}


.navbar>div>a.active {
    background: var(--g-c);
    
}

.navbar .active span {
    width: 80%;
    opacity: 1;


}


.navbar_container>div:nth-child(2) {
    width: 100px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    justify-content: center;
    
}

.navbar_container>div:nth-child(2)>span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar_container>div:nth-child(2)>span>img {
    width: 30px;
    height: 30px;
}


/* Sign Out container */
.signOut_container {
    position: relative;
    transition: 0.3s;
    border-radius: none;
    border-radius: 8px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    min-height: 40px !important;
    max-height: 40px !important;
    
    
}

.signOut_container>div {
    border-radius: 50%;
    display: flex;
    overflow: hidden;
    height: 20px;
    width: 20px;

    
}

.signOut_container>div>img {
    width: 100%;
    height: 100%;
}

.signOut_container:hover {
    background: var(--g-c);
    border-end-end-radius: 0px;
    border-end-start-radius: 0px;

}



.signOut_container:hover > ul {
    height: 100%;
    opacity: 1;

}

.signOut_container>ul {
    bottom: -40px;
    opacity: 0;
    border-bottom-left-radius: 5px;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    transition: 0.6s;
    height: 100%;
    
}

.signOut_container>ul:hover {
    border-bottom-left-radius: 5px;
}

.signOut_container>ul>li {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: 0.3s;
    min-height: 40px;
    width: 200px;
    position: relative;
    background: var(--g-c);
    padding: 0 30px;
    
    
}

.signOut_container>ul>li>a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.signOut_container>ul>li:last-child {
    border-end-end-radius: 8px;
    border-end-start-radius: 8px;
}


.signOut_container>ul>li:hover {
    filter: brightness(80%);

}

@keyframes display_line {
    0% {
        width: 0%;
    }

    100% {

        width: 85%;
    }
}


.navbar_container.sticky.navbar_container>div:nth-child(2) {
    opacity: 0;
}

.nav_bar_notifications {
    position: relative;
}

.nav_bar_notifications>div {
    left: 0;
    padding: 0 0 0 28px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
}

.navbar_not_count {
    height: 25px;
    width: 25px;
    background-color: crimson;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 30px;
    font-weight: 800;
}

.nav_bar_notifications>div>ul {
   
    position: absolute;
    list-style: none;
    transition: 0.3s;
    border-radius: 0 0 4px 4px;
    opacity: 0;
    width: 360px;
    left: -360px;
    top:  0;
    display: none;

    
}
.nav_bar_notifications>div:hover > ul{
    animation: show-in 1s;
    animation-fill-mode: both;
    display: block;
    
}

.navbar_user_icon{
    display: none;
}