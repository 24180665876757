.loading_page_loading_screen{
    position: fixed;
    background: radial-gradient(var(--f-c2-1),var(--f-c-1));
    top: 0;
    bottom: 0;
    z-index:  10;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
}


.loading_page_loading_screen > div:nth-child(1) {
    height: 450px;
    min-width: 310px;
    width: 90%;
    max-width: 700px;  
    position: relative;


}

.loading_page_loading_screen > div:nth-child(1) > h1 {
    color: white;
    font-family: var(--font-amalfi);
    font-weight: lighter;
    animation: mask 5s;
    animation-fill-mode: both;
    font-size: 6rem;
}
.loading_page_loading_screen > div:nth-child(2){
    height: 100px;
    width: 100%;
    position: absolute;
    bottom: 0;
}
.loading_page_loading_screen > div > img{
    width: 100%;
    height: 100%;
    animation: mask 5s;
    animation-fill-mode: both;
}

@keyframes mask {

    0%{
        opacity: 0;
        /* transform: scale(1.2); */
        
    }
  
    
    100%{
       opacity: 1;
       /* transform: scale(1); */
    }

  }

  @media only screen and (max-width:700px) {
    .loading_page_loading_screen > div:nth-child(1) > h1 {
        
        font-size: 3rem;
    }
}