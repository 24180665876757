@import '../../../../shared/variables';

.management-lat-nav-bar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    background: black;
    min-height: 94vh;
    height: auto;
    width: 200px;
    transition: $transition-time;
    z-index: 10;

    @media only screen and (min-width: $tablet-min-screen) and (max-width:$tablet-max-screen) {
        position: fixed;
        top: 0;
        height: 100%;
    }

    @media only screen and (max-width: $mobile-max-screen) {
        position: fixed;
        top: 0;
        height: 100%;

    }

    &.collapse {
        transition: $transition-time;
        width: 60px;
        overflow: hidden;
        height: auto;

        &>ul>li>a>p,
        &>ul>li>button>p {
            // display: none;
            opacity: 0;
            position: absolute;
        }


        @media only screen and (min-width: $tablet-min-screen) and (max-width:$tablet-max-screen) {
            width: 0px;
            height:100%;
            &>ul>li>button {
                & img {
                    filter: grayscale(1%) contrast(100%) brightness(1%) !important;
                    mix-blend-mode: multiply;
                }
            }
        }

        @media only screen and (max-width: $mobile-max-screen) {
            width: 0px;
            height:100%;
            &>ul>li>button {
                & img {
                    filter: grayscale(1%) contrast(100%) brightness(1%) !important;
                    mix-blend-mode: multiply;
                }
            }
        }

    }

    &>ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: auto;
        min-height: 400px;
        width: 100%;
        gap: 5px;
        position: relative;

        &>li {
            width: 100%;

            @media only screen and (min-width: $tablet-min-screen) and (max-width:$tablet-max-screen) {
                &:nth-child(2) {
                    margin-top: 30px;
                }
            }

            @media only screen and (max-width: $mobile-max-screen) {
                &:nth-child(2) {
                    margin-top: 30px;
                }
            }

            &.active {
                background-color: rgb(83, 83, 83);
            }

            &:hover {
                background-color: rgb(33, 33, 33);
            }

            &:nth-child(1) {
                margin-bottom: 30px;
            }

            &>button {
                @media only screen and (min-width: $tablet-min-screen) and (max-width:$tablet-max-screen) {
                    position: fixed;
                    width: 30px !important;
                    height: 30px !important;
                    max-width: 30px !important;
                    min-width: 30px !important;
                    left: 2px;
                    top: 5px;

                    &>p {
                        display: none;
                    }
                }

                @media only screen and (max-width: $mobile-max-screen) {
                    position: fixed;
                    left: 2px;
                    top: 5px;
                    width: 30px !important;
                    height: 30px !important;
                    max-width: 30px !important;
                    min-width: 30px !important;

                    &>p {
                        display: none;
                    }
                }
            }

            &>a,
            &>button {

                padding: 15px;
                align-items: center;
                width: 100%;
                gap: 5px;

                &>img {
                    width: 25px;

                    height: 25px;
                }

                &>p {

                    width: 100%;
                }
            }
        }
    }

}