@import '../../variables', '../../effects';
.screen-message-overlay{
    z-index: 15;
}
.screen-message {
    animation: show-in-from-below 500ms;
    animation-fill-mode: both;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    padding: 30px;
    box-sizing: border-box;
    min-width: $min-width;
    border-radius: $br-rd;
    max-width: 550px;
    width: 100%;
    max-height: 400px;
    height: 100%;
    z-index: 1;

    @media only screen and (max-width: $mobile-max-screen) {
        width: 100vw !important;
        height: 100vh !important;
        max-height: 100% !important;
        max-width: 100% !important;
        position: absolute;
        left: 0;
        top: 0;
    }

    &>h3 {
        font-weight: 900;
        font-size: 1.5rem;
        width: 100%;
        height: 30px;
        text-align: center;

    }

    &>p {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-height: 120px;
        text-align: center;
    }

    &>a {
        width: 200px;
    }

}