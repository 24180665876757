@import '../../../shared/effects','../../../shared/variables';
.management{
    display:flex;
    min-height: 93.4vh;
    height: 100%;
    &-users,&-news,&-academies,&-disciplines,&-events{
        display: flex;
        flex-direction: column;
        & > .divider{
            min-height: 60px;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            animation: show-in $transition-time;
            animation-fill-mode: both;
        }
        & > .view-subsection{
            animation: show-in $transition-time;
            animation-fill-mode: both;
            animation-delay: 100ms;
            height: 100%;
            padding-bottom: 5px;
            min-height: 50vh;
            @include padding-sides();
        }

    }
}
