@import '../../variables';

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: transparent;
    min-width: 100px;
    padding: 10px;
    max-height: 20px !important;
    box-sizing: content-box;
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    border-radius: 2px;
    // box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.171);
    font-weight: 700;
    font-size: 0.9rem;
    transition: $transition-time;

    &.type-cmn {
        background-color: #413E3C !important;
        color: #DDE0E0 !important;
        font-weight: 700 !important;
    }

    &.delete-button {
        padding: 0px !important;
        background-color: brown !important;
    }

    &.edit-button {
        padding: 0px !important;
        background-color: rgb(42, 126, 165) !important;
    }

    &.action-button {
        padding: 0px !important;
        background-color: var(--adm-btn-cmn-color) !important;
    }


    &:hover {
        filter: brightness(90%);
    }

    &.type-1-transparent {
        background-color: transparent;
        color: #0A4CFF;

        &:hover {
            color: white;
            background-color: #0A4CFF;
        }
    }


    &.type-2-transparent {
        background-color: transparent;
        color: rgb(8, 194, 123);

        &:hover {
            color: white;
            background-color: rgb(8, 194, 123);
        }
    }

    &.type-3-transparent {
        background-color: transparent;
        color: #fc4771;

        &:hover {
            color: white;
            background-color: #fc4771;
        }
    }
    &.type-4-transparent {
        background-color: transparent;
        color: #FF8718;

        &:hover {
            color: white;
            background-color: #f8a95f;
        }
    }
    &.type-1 {
        background-color: #8eaaf7;
        color: #0A4CFF;
    }

    &.type-2 {
        background-color: rgb(203, 248, 248);
        color: rgb(8, 194, 194);
    }

    &.type-3 {
        background-color: #f5afbf;
        color: #fc4771;
    }

    &.type-4 {
        background-color: #f8a95f;
        color: #FF8718;
    }

    &.type-cmn {
        background-color: $main-color;
        color: white;
    }

    &.type-delete {
        background-color: rgb(253, 48, 48);
        color: white;

    }

    &.type-create {
        background-color: rgb(37, 182, 37);
        color: white;
    }

    &.type-disable {
        background-color: rgb(248, 136, 50);
        color: white;
    }

    &.btn-second-type {
        background: transparent;
        color: white;

        &>p {
            transition: $transition-time;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

}