$background-color:white;
$background-snd-color:rgb(231, 231, 231);
$main-color:#640089;
$main-color-2:#030531;
$main-color-3:#FC8A00;
$main-color-4:white;
$min-width:330px;
$main-background-color: rgba($color: rgb(204, 213, 228), $alpha: 0.3) ;
$field-background: #ebf3fd ;
$text-black-color:black;
$text-white-color:white;
$gradient-color-left-right:linear-gradient(90deg, rgba(0,212,255,1) -40%, $main-color 100%);
$gradient-color-left-right-2:linear-gradient(90deg, white 0%, $main-color 100%);
$gradient-color-bottom-top:linear-gradient(45deg, rgba(0,212,255,1) 0%, $main-color 100%);
$navbar-z-index: 10;

$br-rd: 3px; /* border radius*/
$box-shadow:1px 1px 5px 1px rgba(0, 0, 0, 0.1);
$btn-cmn-bc-color: 10px; /* button common background color*/
//spacing
$desktop-sides-space:30px;
$tablet-sides-space:20px;
$mobile-sides-space:5px;
//Displays sizes
$mobile-max-screen:767px;
$tablet-min-screen:768px;
$tablet-max-screen:1023px;
$desktop-min-screen:1024px;
//effects
$transition-time:300ms;

@mixin padding-sides() {
    box-sizing: border-box;
    // Mobile
    @media only screen and (max-width: $mobile-max-screen) {
        padding-left:$mobile-sides-space;
        padding-right:$mobile-sides-space;
    }
    // Tablet
    @media only screen and (min-width: $tablet-min-screen)  and (max-width:$tablet-max-screen){
        padding-left:$tablet-sides-space;
        padding-right:$tablet-sides-space;
    }
    // Desktop
    @media only screen and (min-width: $desktop-min-screen) {
        padding-left:$desktop-sides-space;
        padding-right:$desktop-sides-space;
    }
}


@mixin padding-sides-management() {
    box-sizing: border-box;
    // Mobile
    @media only screen and (max-width: $mobile-max-screen) {
        padding-left:$mobile-sides-space;
        padding-right:$mobile-sides-space;
    }
    // Tablet
    @media only screen and (min-width: $tablet-min-screen)  and (max-width:$tablet-max-screen){
        padding-left:$tablet-sides-space;
        padding-right:$tablet-sides-space;
    }
    // Desktop
    @media only screen and (min-width: $desktop-min-screen) {
        padding-left:50px;
        padding-right:50px;
    }
}

.padding-sides{
    @include padding-sides();
}
