.overlay {
    display: flex;
    position: fixed !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0 ;
    left: 0 ;
    background-color: rgba($color: #000000, $alpha: 0.4);
    z-index: 12;
    animation: show-in 500ms;
    animation-fill-mode: both;
}