@import "../../_variables.scss", "../../effects";

.contact_container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    z-index: 10;
    animation: show-in $transition-time ease-in-out;
    animation-fill-mode: both;

    &>section {
        width: 100%;
        height: 620px;
        min-width: 310px;
        max-width: 800px;
        min-height: 450px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        animation: show-in 0.3s ease-in-out;

        &>div:nth-child(1) {
            background-image: url("../../../../public/assets/mockup_of_crumpled_poster_on_old_grunge_wall (1) (1).jpg");
            width: 100%;
            height: 100%;
            background-position: center;
            position: absolute;
            background-size: 160%;
            background-repeat: no-repeat;
            filter: blur(3px);

        }

        &>.contact_box {
            background-color: rgba(255, 255, 255, 0.884);

            width: 100%;
            height: 100%;
            display: flex;
            gap: 30px;
            @include padding-sides();
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: $br-rd;
            position: relative;

            &>h3 {
                display: flex;
                font-size: 20px;
                height: 50px;
                align-items: center;
                justify-content: center;
            }

            &>div {
                width: 100%;

                &:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }


    }


}


.buttons-wrapper {
    justify-content: space-between !important;

}

.contact_success_message_box {
    background-color: rgba(255, 255, 255, 0.884);

    width: 90%;
    height: 90%;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.671);
    position: relative;
    transition: 0.6s;
    &>div:nth-child(1) {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 80%;
        &>h3 {
            display: flex;
            text-transform: uppercase;
            font-size: 25px;
            letter-spacing: 3px;
            color: var(--f-c-3);
            font-weight: 900;
            align-items: center;
            justify-content: center;
        }
        &>p {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 30px;
        }
    
    }
    &>div:nth-child(2) {
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        & button {
            height: 40px;
            border: none;
            width: 140px;
            background: linear-gradient(90deg, var(--f-c2-1), var(--f-c-1));
            border-radius: 5px;
            font-size: 14px;
            color: white;
            font-weight: 500;
            transition: 0.6s;
            cursor: pointer;
        }
    }
}





.buttons-wrapper {
    justify-content: space-between !important;
}

@media only screen and (max-width:700px) {

    .contact_box {
        width: 100%;
        border-radius: 0px;
        padding: 10px 0;
        height: 100vh !important;
        max-height: 100vh !important;
    }

}

@media only screen and (max-width:569px) {}