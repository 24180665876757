@import "../../_variables.scss", "../../effects";
.loading_screen{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #000000, $alpha: 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    min-width: 100vw;
    z-index: 10;
    animation: show-in $transition-time ease-in-out;
    animation-fill-mode: both;
}