@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Cookie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap');


@font-face {
  font-family: 'Brittany';
  src: url('../public/fonts/BrittanySignature.ttf') format('truetype');
  /* Puedes agregar estilos adicionales aquí, como font-weight y font-style si es necesario */
}
@font-face {
  font-family: 'Amalfi Coast';
  src: url('../public/fonts/AmalfiCoast.ttf') format('truetype');
  /* Puedes agregar estilos adicionales aquí, como font-weight y font-style si es necesario */
}
:root {
  /* color-scheme: light dark; */
  --sides-margin: 50px;
  --text-padding: 20px;
  --bottom-top-margin: 50px;
  --font-inter: 'Inter', sans-serif;
  --font-bebas: 'Bebas Neue', cursive;
  --font-mont: 'Montserrat', sans-serif;
  --font-anton: 'Anton', sans-serif;
  --font-cookie: 'Cookie', cursive;
  --font-mrdafoe: 'Mr Dafoe', cursive;
  --font-amalfi: 'Amalfi Coast', cursive;
  --font-brittany: 'Brittany', cursive;
  --border-radius: 6px;
  /* f= front & c= color */
  --f-c-1: #640089;
  --f-c-2: #0F61E3;
  --f-c-3: #0F61E3;
  --f-c-4: #C16F6E;
  --f-c-5: #ACC805;
  --f-c2-1: #343ab3;
  --f-c2-2: #A2C497;
  --f-c2-3: #B6D6E8;
  --f-c2-4: #6046D3;
  --f-c2-5: #72D714;
  --neutral-color: #212121;
  /* g= gradient & c=color */
  --g-c: linear-gradient(90deg, var(--f-c2-1), var(--f-c-1));
  --g-c-n: linear-gradient(90deg, #fc9c42, #b81515);
  /* g = gradient */
  --g-success: linear-gradient(90deg, #24FFB3, #22B381);
  --g-warning: linear-gradient(90deg, #ffaa69, #FF7B00);
  /* f=forever */
  --g-warning-f: linear-gradient(90deg, #FF6262, #5D5D5D);
  --adm-btn-cmn-color: rgb(94, 20, 163);
  --adm-btn-cancel-color: #fb1349;
  --adm-btn-action-color: #a80f70;
  --adm-btn-confirm-color: rgb(20, 183, 183);
}

/* @media (prefers-color-scheme: dark) {
  .view,.view-subsection {
    background-color: rgb(92, 92, 92) !important;
  }

  table {
    background-color: red !important;
  }
  input,.field-uneditable > h3 {
    background-color: #7d8794 !important;
  }

} */

.academy-color {
  height: 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;


}
.academy-color>p {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 15px;
  padding: 10px;
  color: white;
  text-align: center;
  font-weight: 600;
  background-color: black;
}

input {
  outline: none;
  border: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

p {
  font-family: var(--font-inter);
  overflow-wrap: break-word;

}



* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--font-inter);

}

html {
  font-size: 16px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(253, 146, 104, 0.877);

}

*::-webkit-scrollbar-button {
  background-color: rgba(234, 234, 234, 0.877);
  /* border-radius: 6px; */
}

*::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(212, 212, 212, 0.877);
  /* overflow: hidden; */
  /* border-radius: 20px; */
}


.view {
  height: 100%;
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  position: relative;
}




.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.box-sw {
  box-shadow: -1px 1px 7px 0px rgba(0, 0, 0, 0.665);
  border-radius: 3px;
}






.thin_scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
}

.thin_scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: rgba(25, 21, 39, 0)
}

.thin_scrollbar::-webkit-scrollbar-button {
  background-color: rgba(111, 114, 158, 0);
  height: 10px;
  width: 5px;
}


.thin_scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(32, 25, 92);
  border-radius: 6px;
}

body {
  width: 100%;
  height: 100%;

}


.blank_margin {
  box-shadow: 1px 1px 3px 0 rgb(70, 70, 70);
  z-index: 3;
  height: 100px;
  width: 100%;
  background: rgba(223, 66, 139, 0.582);
  background: linear-gradient(90deg, #ac4147 0%, #8f2325 25%, #cf5854 50%, #88315b 75%, #542051 100%);
}

.f-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-cmn {
  min-width: 100px;
  padding: 5px 5px;
  min-height: 30px;
  border: none;
  outline: none;

  cursor: pointer;
  text-decoration: none;
  border-radius: 2px;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.171);
  font-weight: 700;
  font-size: 12px;
  z-index: 3;
}

.btn-close-pop-up {
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  max-width: 50px;
  max-height: 50px;
  top: -15px;
  right: -15px;
  outline: none;

  border: none;
}

.btn-adm-act {
  background-color: var(--adm-btn-action-color);
  color: white;
}

.btn-adm-conf {
  background-color: var(--adm-btn-confirm-color);
  color: white;
}

.btn-adm-ccl {
  background-color: var(--adm-btn-cancel-color);
  color: white;
}

.btn-adm-cmn {
  background-color: var(--adm-btn-cmn-color);
  color: white;
}


.btn-cmn:hover {
  filter: brightness(130%);
  /* font-size: 16px; */
  transition: 0.3s;
}


.titles {
  font-weight: 900;

  text-transform: uppercase;
  font-family: var(--font-inter);
  font-size: 3.5rem;
  letter-spacing: 1.5px;
}

.subtitles {
  font-family: var(--font-amalfi);
  font-size: 1.9rem;
  font-weight: 100;
  font-weight: 900;
}

.text {
  position: relative;
  font-size: 0.8rem;
  letter-spacing: 1px;
  line-height: 35px;
  display: block;
  font-family: var(--font-inter);
  font-weight: 300;
}

.text:first-letter {
  text-transform: capitalize
}


/* Grey COMMON buttons */

.grey-btn {

  opacity: 0.8;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 13px;
}

.grey-btn:hover {
  transition: 0.3s;
  opacity: 1;
}

.item-circle {
  display: block;
  width: 5px;
  height: 5px;
  max-height: 5px;
  max-width: 5px;
  background-color: white;
  border-radius: 50%;
  margin-right: 10px;

}




/* Formations display info */
.formation-gl-item {
  margin: 5px 0;
}

.formation-gl-item>div:nth-child(1) {
  width: 100%;
  padding: 5px;
}


.formation-gl-item>div:nth-child(1)>p {

  font-size: 0.8rem;
  min-height: 85px;
}

.formation-gl-item>div:nth-child(2) {
  height: 30px;
  display: flex;
  justify-content: flex-end;
}

.formation-gl-item>div:nth-child(2)>button {
  margin-left: 30px;

}

.formation-gl-item>div:nth-child(2)>button:nth-child(1) {
  color: var(--adm-btn-action-color);
}


/* 1200PX MEDIA */
@media only screen and (max-width:1366px) {

  :root {
    --sides-margin: 20px;
  }

  .titles {
    font-size: 1.6rem !important;

  }

}

@media only screen and (max-width:460px) {
  :root {
    --text-padding: 5px;
    --sides-margin: 30px;
  }

  .titles {
    text-align: center;
    letter-spacing: 0px;
    font-size: 1.5rem;
  }

  .subtitles {
    text-align: center;
    font-size: 1.1rem;
  }
}

/* ============================== Effects / Animations  ==============================*/



@keyframes show-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes show-in-above {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes show-in-side {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}


@keyframes swipe-in {
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }

  50% {}

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


@keyframes float {
  0% {
    opacity: 1;
    border-radius: 0;
    transform: translateY(0) rotate(360deg);
    /* transform: translateY(0); */
  }

  100% {
    opacity: 0;
    border-radius: 50%;
    transform: translateY(-1000px) rotate(720deg);

  }
}


@keyframes fade-slide-in-left-to-right {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes flag {
  0% {
    height: 0px;
  }

  100% {
    height: 2000px;
  }

}



@keyframes simple-fade-slide-in-right-to-left {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-slide-in-right-to-left {
  0% {
    transform: translateX(500px) translateY(40px);
    opacity: 0;
  }

  100% {
    transform: translateX(400px) translateY(40px);
    opacity: 1;
  }
}


@keyframes fade-slide-in-top-to-bottom {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


@keyframes right_arrow_animation {
  0% {
    transform: translateX(0) scale(1);
  }

  50% {
    transform: translateX(-20px) scale(0.9);
  }

  100% {
    transform: translateX(0) scale(1);
  }
}

@keyframes left_arrow_animation {
  0% {
    transform: translateX(0) scale(1) rotate(180deg);
  }

  50% {
    transform: translateX(20px) scale(0.9) rotate(180deg);
  }

  100% {
    transform: translateX(0) scale(1) rotate(180deg);
  }

}


@keyframes wiggle {
  0% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(60deg);
  }

}

@keyframes width_expansion {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }

}

.form-subtitles {
  font-size: 1.6rem;
  color: #212121;
  display: flex;
  align-items: flex-end;
  min-width: 310px;
  position: relative;

}

.form-subtitles>.warning-input-message {
  left: 300px;
}

.show {
  transition: 2s;
  opacity: 1;
}

.hidden {
  transition: 2s;
  opacity: 0;
}


.show_in_above {
  animation: show-in-above 500ms;
  animation-fill-mode: both;
}

.show_in_side {
  animation: simple-fade-slide-in-right-to-left 500ms;
  animation-fill-mode: both;
}

.show_side_to_right {
  animation: fade-slide-in-left-to-right 500ms;
  animation-fill-mode: both;
}

.hidden_side_to_right {
  opacity: 0;
}

.hidden_side {
  opacity: 0;
}

.hidden_above {
  opacity: 0;
}


.show_in {
  animation: show-in 1.4s;
  animation-fill-mode: both;
}

.show-in-above {
  animation: show-in-above 1s;

}

.fade-slide-in-right-to-left {
  animation: fade-slide-in-right-to-left 2s;
}

.width_expansion {
  animation: width_expansion 2s ease;
}

/* =============== =============== =============== */

.delete-pop-up {
  z-index: var(--z-index-highest-level);
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
}

.delete-pop-up>div:nth-child(1) {
  background-color: white;
  width: 450px;
  height: 250px;
  padding: 15px;
  border-radius: 5px;
  margin: 200px auto;
  animation: show-in 1s;
  box-shadow: 0 0 3000px 1000px rgba(0, 0, 0, 0.5);

}

.delete-pop-up>div:nth-child(1)>div:nth-child(2) {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

}

.discipline-cmn-box {
  color: white;
  height: 25px;
  padding: 0 10px;

  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 0.8rem;
}

/* =================================== */
.off {
  display: flex;
  animation: landingLoadingScreen;
  animation-delay: 2s;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.hide {
  opacity: 0;
}

@keyframes landingLoadingScreen {

  0% {
    opacity: 1;
  }


  100% {
    visibility: hidden;
    opacity: 0;
    display: none;
  }
}

@keyframes show-visibility {

  0% {
    opacity: 1;
    visibility: hidden;
  }


  100% {
    visibility: visible;
    opacity: 1;
    display: block;
  }
}

@media only screen and (max-width:869px) {
  .text {
    letter-spacing: 1px;
    line-height: 25px;
  }

  .form-subtitles {
    font-size: 1.4rem;
  }

}

@media only screen and (max-width:690px) {
  .btn-close-pop-up {
    top: 12px;
    right: 25px;
    box-shadow: 0 0 3px 0 black;
  }

  .text {
    line-height: 23px;
  }

}

/* ======================= */

/* =========================== */

.adm_subsection_wrapper {
  width: 100%;
  height: 10%;
  max-height: 50px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;

}

.adm_subsection_wrapper>ul {
  height: 100%;
  list-style: none;
  display: flex;
  gap: 15px;

}

.adm_subsection_wrapper>ul>li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  position: relative;
  cursor: pointer;
  font-size: 0.8rem;
}

.adm_subsection_wrapper>ul>li>span {
  position: absolute;
  bottom: 0;
  height: 5px;
  width: 0%;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

}

.adm_subsection_wrapper>ul>li:hover>span {
  transition: 0.3s;
  width: 90%;
  opacity: 0.6;
  background-color: rgb(128, 21, 74);
}

/* =========================== */
/* =========================== */
.adm_subsection_wrapper>div {
  width: 20%;
  height: 100%;
}

.adm_subsection_wrapper>div>p {
  height: 50%;
  font-weight: 700;
  font-size: 0.8rem;
  color: grey;
  text-align: right;
}

.adm_subsection_wrapper>div>p>strong {
  font-weight: 900;
  margin-left: 5px;
  color: black;
}

.validation-message {
  width: 100%;
  padding: 5px 0;
  text-align: center;
  font-weight: 600;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 78, 78);
  border-radius: 4px;
  height: auto !important;
  color: white;
  animation: show-in 300ms;
  animation-fill-mode: both;
  z-index: 1;

}
.notification-chip{
  display: block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(243, 98, 98);
  color: white;
  font-weight: 800;
  margin-left: 5px;
}