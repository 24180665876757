.radio_button{
    display: flex;
    width: 100%;
    height: 30px;
    gap: 10px;
    align-items: center;
    
}
.radio_button > button {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: solid 0.2px var(--f-c2-4);
    cursor: pointer;
    
}

.radio_button > button > span{
    display: block;
    border-radius: 50%;
    width: 80%;
    height: 80%;
    background-color: transparent;
    transition: 0.2s;
}


.radio_button > p {
    font-size: 0.8rem;
    
    display: flex;
    align-items: center;
}