@import '../../variables', '../../effects';

.action-confirmation-pop-up {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.555);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    z-index: 13;
    animation: show-in $transition-time;
    animation-fill-mode: both;
    &>form {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        background-color: white;
        border-radius: $br-rd;
        width: 100%;
        height: 100%;
        max-width: 40vw;
        padding: 20px;
        min-width: $min-width;
        max-height: 400px;
        text-align: center;
        gap: 30px;
        & > div {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
        }

    }
}