
.notification{
    height: 120px;
    position: relative;    
    border-bottom: 0.1px solid;
}
.notification:hover {
    filter: brightness(120%);
}



.notification:last-child {
    border-radius: 0 0 4px 4px;
    border-bottom: transparent;
}

.notification>a {
    text-decoration: none;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    
}
.notification>a>h4{
    color: white;
}
.notification>a>p {
    width: 90%;
    color: white;
    margin-bottom: auto;
    
    
}

.notification>a>p:nth-child(2) {
    font-size: 0.7rem;
}

.notification>a>p:nth-child(3) {
    font-weight: 700;
    color: rgb(255, 255, 255);
    text-align: right;
    width: 100%;
    
    transform: scale(0.9);
    font-size: 0.6rem;
    margin-top: auto;
}

.notification>div {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 16px;
    font-weight: 900;
    transition: 0.3s;
    height: 25px;
    width: 25px;
    max-height: 25px;
    max-width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    z-index: 3;
    cursor: pointer;
}

.notification>div:hover {
    transform: scale(1.1);
}

@media only screen and (max-width:660px) {
    .notification {
        height: 180px;
        

    }
    .notification>a>p{
        height: 80%;
    }

}