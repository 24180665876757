@media only screen and (max-width:1366px) {

    .navbar_container{
        max-height: 50px;
    }
    .navbar>div:nth-child(1)>a {
        width: 120px;
    }
    .signOut_container {
        position: relative;
        transition: 0.3s;
        border-radius: none;
        border-radius: 8px;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        min-height: 40px !important;
        max-height: 40px !important;
    }

    .signOut_container>ul{
        bottom: -40px;
    }

}



/* ============================ WIDTH 765px =============================== */

@media only screen and (max-width:660px) {
    .navbar_container{
        background-color: rgba(0, 0, 0, 0.786);
        max-height: 40px !important;
    }
    .navbar_container > div:nth-child(2){
        display: none;
        
    }
    
    .navbar>div:nth-child(1) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 0px;
    }
    .navbar_login_button{
        position: absolute;
        right: 0;
    }
    .navbar>div:nth-child(1) > div{
        position: absolute;
        right: 0;
        margin: 0;
        border-radius: 0px;
    }


    .navbar > div:nth-child(1) > a {
        margin: 0;
        width: 80px;
        border-radius:0px ;
        
    
    }
    .navbar > div:nth-child(1) > a > span{
        display: none;
        
    }
    .signOut_container {

        background-color: rgba(0, 0, 0, 0.432);
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        transition: 0.4s;
        cursor: pointer;
        max-width: 60px;
        min-width: 60px;
    }

    .navbar_user_icon{
        align-items: center;
        display: flex;
        justify-content: center;
        
    }
    .navbar_user_item{
        display: none;
    }

    .signOut_container>ul>li,
    .signOut_container>ul>li > a  {
        width: 50px;
        align-items: center;
        display: flex;
        justify-content: center;      
        
        
    }

    .nav_bar_notifications > div{
        display: flex;
    }
    .navbar_user_icon.not{
        transform: translateX(-8px);
    }
    
    .navbar_not_count{
        right: 6px;
        top: 0;
        transform: scale(0.7);
    }

    .nav_bar_notifications>div>ul {

        width: 250px;
        left: -250px;
        
        
    }
}