.footer {
    width: 100%;
    background-color: var(--neutral-color);
    /* overflow: hidden; */


    z-index: 3;
}

.footer>span {
    height: 80px;
    width: 100%;
    display: block;
}

.footer>div {
    height: 400px;
    background-size: cover;
    position: relative;
    background-position: center !important;
}

.footer>div>div {
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;
    opacity: 0.5;

}

.footer * {
    color: white;
}

.footer>div>section {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;

}

/* ==================================== */
.footer>div>section>div:nth-child(1) {
    height: 85%;
    display: flex;
    gap: 25px;
    padding: 20px var(--sides-margin);

}

.footer>div>section>div:nth-child(1)>section:nth-child(1) {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;

}

.footer>div>section>div:nth-child(1)>section:nth-child(1)>div:nth-child(1) {
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


/* .footer > div > section > div:nth-child(1) > section:nth-child(1)> div  > img {
    height: 50%;
    width: 60%;
    min-width: 150px;
    
} */
.footer>div>section>div:nth-child(1)>section:nth-child(1)>div {
    display: flex;

    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 150px;
    


}


/* =========================== */
.footer>div>section>div:nth-child(1)>section:nth-child(2) {
    width: 60%;
    padding: 20px 0;
    display: grid;
    grid-template-columns: 30% 30% 40%;
    
}

.footer>div>section>div:nth-child(1)>section:nth-child(2)>div {

    display: flex;
    flex-direction: column;
    justify-content: space-around;


}

.footer>div>section>div:nth-child(1)>section:nth-child(2)>div>h4 {

    height: 20%;
    display: flex;
    align-items: flex-end;
    font-size: 1rem;
}

/* =========================== */
.footer>div>section>div:nth-child(1)>section:nth-child(2)>div>div {
    height: 70%;

}

.footer>div>section>div:nth-child(1)>section:nth-child(2)>div:nth-child(3)>div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer>div>section>div:nth-child(1)>section:nth-child(2)>div>div>p,
.footer>div>section>div:nth-child(1)>section:nth-child(2)>div>div>a {
    font-size: 0.9rem;
    font-weight: 200;
    margin: 10px 0;
    text-shadow: 1px 0 3px black;
    text-decoration: none;


}

.footer>div>section>div:nth-child(1)>section:nth-child(2)>div:nth-child(3)>div>a {
    color: white;
    background: var(--g-c);
    width: 100%;
    text-align: center;
    font-weight: 400;
}

.footer>div>section>div:nth-child(1)>section:nth-child(2)>div>div>a {
    font-weight: 200;
    width: 100%;
    display: block;
    text-decoration: none;
}

.footer>div>section>div:nth-child(2) {
    height: 15%;
    width: 100%;
    display: flex;
    position: relative;
    

}

.footer>div>section>div:nth-child(2) p,
.footer>div>section>div:nth-child(2) a {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;

}

.footer>div>section>div:nth-child(2) span {
    position: absolute;
    height: 1px;
    width: 100%;
    top: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), white, rgba(0, 0, 0, 0));
}

@media only screen and (max-width:800px) {

    .footer>div {
        height: 500px;
    }

    .footer>div>section>div:nth-child(1) {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 20px var(--sides-margin);

    }

    .footer>div>section>div:nth-child(1)>section:nth-child(1) {

        width: 100%;
    }

    .footer>div:nth-child(2)>section>div:nth-child(1)>section:nth-child(2)>div:nth-child(1),
    .footer>div:nth-child(2)>section>div:nth-child(1)>section:nth-child(2)>div:nth-child(2) {
        display: none;
    }

    .footer>div>section>div:nth-child(1)>section:nth-child(2) {
        width: 100%;
        height: 100%;
    }

    .footer>div>section>div:nth-child(1)>section:nth-child(2)>div {
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
    }

    .footer>div>section>div:nth-child(1)>section:nth-child(2)>div>div>button {
        color: white;
        background: var(--g-c);
        width: 100%;
    }

    .footer>div:nth-child(2)>section>div:nth-child(1)>section:nth-child(2)>div:nth-child(3) {
        width: 100%;

        grid-column: 1/4;
    }

    .footer>div>section>div:nth-child(2) p:nth-child(3) {
        /* border: solid; */
        display: none;
    }
}