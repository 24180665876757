@import '../../variables';

.text-area-container {
    width: 100%;
    min-width: $min-width;
    height: 100%;
    position: relative;
    @media only screen and (max-width: $mobile-max-screen) {
        flex-grow: 1 !important;
        max-width: $mobile-max-screen !important;
    }
    & > p{
        animation: show-in $transition-time;
        animation-fill-mode: both;
        font-weight: 500;
        color: red;
        font-size: 0.9rem;
    }

    &>section {

        border-radius: $br-rd;
        height: 100%;
        position: relative;
        &>textarea {
            background-color: transparent;
            width: 100% !important;
            height: 80%;
            min-height: 150px;
            max-height: 500px;
            max-width: 100%;
            padding: 10px;
            border: none;
            outline: none;
            field-sizing:content;
            &::first-letter{
                text-transform: uppercase;
            }
        }

        &>.warning-input-message {
            position: absolute;
            right: 10px;
            bottom: 5px;
        }

        &>.input-maxlenght {
            display: block;
            position: absolute;
            
            bottom: 8px;
            right: 30px;
            font-size: 0.9rem;
            font-weight: 700;
            color: grey;
        }
    }


    &.type-1 {
        &>section {
            background-color: $field-background;
            &>textarea {
                text-align: left;
                color: $main-color-2;
                font-weight: 600;

                &::placeholder {
                    color: $main-color-2;
                    font-size: 1.1rem;
                }
            }
            
        }
    }



}


input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}