@import "../../_variables.scss", "../../effects";

.login_container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    width: 100%;
    height: 100%;
    z-index: 10;
    animation: show-in $transition-time ease-in-out;
    animation-fill-mode: both;

    &>section {
        width: 675px;
        height: 650px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        overflow: hidden;
        animation: show-in 0.3s ease-in-out;

        &>div:nth-child(1) {
            background-image: url("../../../../public/assets/login_image.jpg");
            width: 100%;
            height: 100%;
            background-position: center;
            position: absolute;
            background-size: 160%;
            background-repeat: no-repeat;
            filter: blur(3px);
        }

        &>.login_box,
        &>.forgot_password_box {
            background-color: rgba(255, 255, 255, 0.884);
            flex-direction: column;
            gap: 40px;
            width: 590px;
            height: 520px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 8px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.671);
            position: relative;
        }
    }

}






.login_box>div,
.forgot_password_box>div {
    width: 80%;
    position: relative;
}

.login_box>div:nth-child(1) p,
.forgot_password_box>div:nth-child(1) p {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #711E72;
    font-weight: 600;

}

/* Inputs */
.login_box>div:nth-child(2),
.forgot_password_box>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 20px;
}



/* SignUp - Forgot paswword */
.login_box>div:nth-child(3) {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    justify-content: space-between;

}

.login_box>div:nth-child(3) button {
    height: 40px;
    color: #3758FE;
    font-weight: 600;
    text-decoration: none;
    transition: 0.6s;
    outline: none;
    border: none;
    background-color: transparent;
}

.login_box>div:nth-child(3) button:hover {
    transform: scale(1.03);
}

.login_box>div:nth-child(3) button:nth-child(2) {
    text-align: right;

}



.buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.buttons-wrapper button {
    height: 40px;
    border: none;
    width: 140px;
    background-color: #B637B6;
    background: linear-gradient(90deg, #B637B6 0%, #6E1770 100%);
    border-radius: 5px;
    font-size: 14px;
    color: white;
    font-weight: 500;
    transition: 0.6s;
    cursor: pointer;
}



.buttons-wrapper button:nth-child(1) {
    background: linear-gradient(90deg, #6E1770 0%, #B637B6 100%);
}



@media only screen and (max-width:1366px) {
    .login_container>section {
        transform: scale(0.85);

    }

}

@media only screen and (max-width:466px) {
    .login_container>section {
        width: 100%;
        height: 100%;
        transform: scale(1);

        align-items: flex-end;
    }

    .login_container>section>div:nth-child(2) {
        height: 100vh;
        width: 100%;
        border: solid;
        max-width: 100%;
        border-radius: 0px;
    }

}