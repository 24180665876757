@import '../../../../shared/variables';
.management-nav-bar{
    top: 0;
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: $gradient-color-left-right-2;
    @include padding-sides();
    

    & > .management-nav-section-btn{
        display: flex;
        flex-direction: row;
    }

}

.management-nav-section-btn {
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    list-style: none;
    gap: 10px;

    @media only screen and (max-width: $mobile-max-screen) {
        gap: 0px;
    }

    &>.nav-btn {
        height: 100%;
        & > a{
            & > p{
                @media only screen and (max-width: $mobile-max-screen) {
                    display: none;
                }
            }
            & > img {
                display: none;
                width: 20px;
                @media only screen and (max-width: $mobile-max-screen) {
                    display: block;
                }
            }
        }
    }

    & > .notification-btn-wrapper {
        position: relative;
        transition: 0.3s;
        & > div{
            transition: 0.3s;
            opacity: 0;
            z-index: -3;
            position: absolute;
            width: 340px;
            top: 60px;
            right: 0;
        }

        &:hover {
            z-index: 1 !important;
            & > div {
                opacity: 1 !important;
                z-index: 3 !important;
            }
        }
    }
} 
.nav-logo{
    transition: $transition-time;
    
    &:hover{
        filter: brightness(110%);
    }
    @media only screen and (min-width: $tablet-min-screen) and (max-width:$tablet-max-screen) {

        transform: translateX(40px) scale(0.9);
        filter: brightness(110%);
        width: 30px !important;
        height: 30px !important;
        max-width: 30px !important;
        min-width: 30px !important;

    }

    @media only screen and (max-width: $mobile-max-screen) {
        transform: translateX(40px) scale(0.9);
        filter: brightness(110%);
        width: 30px !important;
        height: 30px !important;
        max-width: 30px !important;
        min-width: 30px !important;

    }
}