@import '../../variables';

.warning-input-message {
    position: absolute;
    &:hover>p {
        display: block;
    }

    &>p {
        top: 13px;
        right: 5px;
        position: absolute;
        width: 280px;
        padding: 10px;
        font-weight: 600;
        font-size: 0.8rem;
        height: auto;
        background-color: black;
        color: white;
        z-index: 10;
        border-radius: $br-rd;
        border-top-right-radius: 0px;
        display: none;
        animation: show-in 300ms;
        animation-delay: 300ms;
        animation-fill-mode: both;
    }
}

.input-container {
    width: 100%;
    min-width: 320px;
    
    position: relative;
    @media only screen and (max-width: $mobile-max-screen) {
        flex-grow: 1 !important;
        max-width: $mobile-max-screen !important;
    }
    & > p{
        animation: show-in $transition-time;
        animation-fill-mode: both;
        font-weight: 500;
        color: red;
        font-size: 0.9rem;
    }

    &>section {
        border-radius: $br-rd;
        height: auto;
        padding: 0px 5px;
        &>input {
            font-size: 0.8rem;
            background-color: transparent;
            width: 80%;
            height: 100%;
            padding: 0px 5px;
            border: none;
            outline: none;
            height: 40px;
        }

        &>.warning-input-message {
            right: 10px;
            top: 14px;
        }

        &>.input-maxlenght {
            display: block;
            position: absolute;
            top: 14px;
            right: 30px;
            font-size: 0.9rem;
            font-weight: 700;
            color: grey;
        }
    }


    &.type-1 {
        &>section {
            background-color: $field-background;
            &>input {
                text-align: left;
                color: $main-color-2;
                font-weight: 600;

                &::placeholder {
                    color: $main-color-2;
                    font-size: 0.9rem;
                }
            }
        }
    }

    &.type-2 {
        width: 90%;
        display: flex;

        &>section {
            &>input {
                text-align: center;
                color: rgb(243, 243, 243);
                font-weight: 600;

                &::placeholder {
                    color: rgb(243, 243, 243);
                    font-size: 1.1rem;
                }
            }

        }
    }

    &.type-3 {
        width: 90%;
        
        &>section {
            background-color: $field-background;
            display: flex;
            height: 50px;
            &>input {
                text-align: left;
                width: 80%;
                box-sizing: border-box;
                color: black;

                &::placeholder {
                    color: black;
                    font-size: 1.1rem;
                }
            }

            &>.warning-input-message {
                top: 17px;
            }

            &>.input-maxlenght {
                top: 16px;
                right: 30px;
            }
        }
    }

    
    &.type-4 {
        width: 90%;
        
        &>section {
            background-color: rgb(220, 220, 220);
            display: flex;
            height: 50px;
            &>input {
                text-align: left;
                width: 80%;
                box-sizing: border-box;
                color: black;

                &::placeholder {
                    color: black;
                    font-size: 1.1rem;
                }
            }

            &>.warning-input-message {
                top: 17px;
            }

            &>.input-maxlenght {
                top: 16px;
                right: 30px;
            }
        }
    }

    


}
.hide-btn{
    background-color: transparent;
    outline: none;
    border: transparent;
    position: absolute;
    right: 70px;
    top: 10px;
    opacity: 0.5;
    cursor: pointer;
    & > img{
        width: 20px;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}