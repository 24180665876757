.app{
  width: 100%;
  height: 100%;
  

}
*{
  scroll-behavior: smooth;
}




